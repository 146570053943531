import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

let API = "https://fn.airwire.io/"

@Injectable()
export class NftService  {
    private events : any = {};
    constructor(private http : HttpClient){}

    // ########## ASSETS ##########
    getAsset(asset_id : string) : Promise<ApiAsset>{
        // https://fn.airwire.io/docs/#/assets/get_wireassets_v1_assets__asset_id_
        let ENDPOINT = `wiremarket/v1/assets/${asset_id}`
        return new Promise((resolve, reject)=>{
            this.http.get<ApiResponseSingle<ApiAsset>>(API + ENDPOINT).subscribe(
                async (res) => { 
                    let a : ApiAsset = res.data
                    if (a.sales && a.sales.length)          a.sale = await this.getSaleID(a.sales[0].sale_id)
                    if (a.auctions && a.auctions.length) a.auction = await this.getAuctionID(a.auctions[0].auction_id)
                    resolve(a)
                }, 
                (err : any) => { reject(err) }
            )
        })
    }
    getAssets(query? : AssetQuery) : Promise<ApiAsset[]>{
        // https://fn.airwire.io/docs/#/assets/get_wireassets_v1_assets
        let ENDPOINT = "wiremarket/v1/assets"
        return new Promise((resolve, reject)=>{
            let PARAMS = query ? this.buildParams(query) : ""
            this.http.get<ApiResponse<ApiAsset>>(API + ENDPOINT + PARAMS).subscribe(
                async (res) => { 
                    let sale_ids : (string | null)[] = res.data.map((a : ApiAsset)=>{ return a.sales && a.sales.length ? a.sales[0].sale_id : null })
                    let sales : ApiSaleDictionary = await this.getSalesID(sale_ids)
                    res.data.forEach((a : ApiAsset)=>{ a.sale = a.sales && a.sales.length ? sales[a.sales[0].sale_id] : undefined })
                    resolve(res.data)
                }, 
                (err : any) => { reject(err) }
            )
        })
    }
    getAssetHistory(asset_id : string): Promise<AssetHistory[]>{
        // Combo of calls to get complete NFT history
        return new Promise(async (resolve, reject)=>{
            let history     : AssetHistory[] = []
            let logs        : ApiLog[]       = await this.getAssetLogs(asset_id)
            let transfers   : ApiTransfer[]  = await this.getTransfers({ asset_id: [asset_id] })
            let sales       : ApiSale[]      = await this.getRawSales({ asset_id })
            let offers      : ApiOffer[]     = await this.getOffers({ asset_id: [asset_id] })

            for (let log of logs){
                if (log.data.hasOwnProperty('authorized_minter')) this.emit('found-minter', log.data.authorized_minter)
                history.push({
                    type: 'log',
                    id: log.log_id,
                    info: log.name,
                    txid: log.txid,
                    date: new Date(+log.created_at_time),
                    creator: log.data.authorized_minter,
                    block: log.created_at_block,
                })
            }
            for (let transfer of transfers){
                history.push({
                    type: 'transfer',
                    id: transfer.transfer_id,
                    info: transfer.memo,
                    txid: transfer.txid,
                    date: new Date(+transfer.created_at_time),
                    block: transfer.created_at_block,
                    data: transfer
                })
            }
            for (let sale of sales){
                await this.getTrxIdFromBlock(sale.updated_at_block)
                history.push({
                    type: 'sale',
                    id: sale.sale_id,
                    info: 'List for Sale: ' + this.numberWithCommas(this.apiPrice(sale.price.amount, sale.price.token_precision)) + ' WIRE',
                    txid: await this.getTrxIdFromBlock(sale.updated_at_block),
                    date: new Date(+sale.created_at_time),
                    block: sale.updated_at_block,
                    data: sale
                })
            }
            for (let offer of offers){
                history.push({
                    type: 'offer',
                    id: offer.offer_id,
                    info: offer.memo == 'sale' ? `Sale Offer - ID # ${offer.offer_id}` : !offer.is_recipient_contract && !offer.is_sender_contract ? "Trade Offer: " + offer.memo : offer.memo,
                    txid: await this.getTrxIdFromBlock(offer.updated_at_block),
                    date: new Date(+offer.created_at_time),
                    block: offer.updated_at_block,
                    data: offer
                })
            }

            // console.log(logs);
            // console.log(transfers);
            // console.log(sales);
            
            let sorted = history.sort((a, b) => b.date.getTime() - a.date.getTime())
            resolve(sorted)
        })
    }
    getAssetLogs(asset_id : string) : Promise<ApiLog[]>{
        // https://fn.airwire.io/docs/#/assets/get_wiremarket_v1_assets__asset_id__logs
        let ENDPOINT = `wiremarket/v1/assets/${asset_id}/logs`
        return new Promise((resolve, reject)=>{
            this.http.get<ApiResponse<ApiLog>>(API + ENDPOINT).subscribe(
                async (res) => { resolve(res.data) }, 
                (err : any) => { reject(err) }
            )
        })
    }

    // ####### COLLECTIONS ########
    getCollection(name: string): Promise<ApiCollection>{
        // https://fn.airwire.io/docs/#/collections/get_wiremarket_v1_collections
        let ENDPOINT = "wireassets/v1/collections/"
        return new Promise((resolve, reject)=>{
            this.http.get<ApiResponse<ApiCollection>>(API + ENDPOINT + name).subscribe(
                (res : any) => { resolve(res.data) }, 
                (err : any) => { reject(err) }
            )
        })
    }
    getCollections(query? : CollectionQuery): Promise<ApiCollection[]>{
        // https://fn.airwire.io/docs/#/collections/get_wiremarket_v1_collections
        let ENDPOINT = "wireassets/v1/collections"
        return new Promise((resolve, reject)=>{
            let PARAMS = query ? this.buildParams(query) : ""
            this.http.get<ApiResponse<ApiCollection>>(API + ENDPOINT + PARAMS).subscribe(
                (res : any) => { resolve(res.data) }, 
                (err : any) => { reject(err) }
            )
        })
    }

    /**
     * Returns true or false based on whether a collection with 'name' already exists.
     * 
     * @param name name of collection
     * @returns A boolean wether the collection exists or not
     */
    collectionExists(name: string): Promise<boolean>{
        return new Promise((resolve, reject)=>{
            this.getCollection(name).then((res) => {
                resolve(true);
            }).catch((err) => {
                resolve(false);
            })
        })
    }

    // ######### SCHEMAS #########
    getSchemas(query? : SchemaQuery): Promise<ApiSchema[]>{
        // https://fn.airwire.io/docs/#/schemas/get_wiremarket_v1_schemas
        let ENDPOINT = "wireassets/v1/schemas"
        return new Promise((resolve, reject)=>{
            let PARAMS = query ? this.buildParams(query) : ""
            this.http.get<ApiResponse<ApiSchema>>(API + ENDPOINT + PARAMS).subscribe(
                (res : any) => { resolve(res.data) }, 
                (err : any) => { reject(err) }
            )
        })
    }

    // ########## OFFERS ##########
    getOffers(query? : OfferQuery) : Promise<ApiOffer[]>{
        // https://fn.airwire.io/docs/#/offers/get_wiremarket_v1_offers
        let ENDPOINT = `wiremarket/v1/offers`
        return new Promise((resolve, reject)=>{
            let PARAMS = query ? this.buildParams(query) : ""
            this.http.get<ApiResponse<ApiOffer>>(API + ENDPOINT + PARAMS).subscribe(
                (res : any) => { resolve(res.data) }, 
                (err : any) => { reject(err) }
            )
        })
    }
    
    // ######## TRANSFERS #########
    getTransfers(query? : TransferQuery) : Promise<ApiTransfer[]>{
        // https://fn.airwire.io/docs/#/assets/get_wiremarket_v1_assets__asset_id__logs
        let ENDPOINT = `wiremarket/v1/transfers`
        return new Promise((resolve, reject)=>{
            let PARAMS = query ? this.buildParams(query) : ""
            this.http.get<ApiResponse<ApiTransfer>>(API + ENDPOINT + PARAMS).subscribe(
                (res : any) => { resolve(res.data) }, 
                (err : any) => { reject(err) }
            )
        })
    }

    // ######### ACCOUNTS #########
    getAccounts(query? : AccountQuery): Promise<ApiOwner[]>{
        // https://fn.airwire.io/docs/#/accounts/get_wireassets_v1_accounts
        let ENDPOINT = "wireassets/v1/accounts"
        return new Promise((resolve, reject)=>{
            let PARAMS = query ? this.buildParams(query) : ""
            this.http.get<ApiResponse<AccountQuery>>(API + ENDPOINT + PARAMS).subscribe(
                (res : any) => { resolve(res.data) }, 
                (err : any) => { reject(err) }
            )
        })
    }
    getAccount(username : string): Promise<ApiAccount>{
        // https://fn.airwire.io/docs/#/accounts/get_wireassets_v1_accounts__account_
        let ENDPOINT = "wireassets/v1/accounts/"
        return new Promise((resolve, reject)=>{
            this.http.get<ApiResponse<ApiAccount>>(API + ENDPOINT + username).subscribe(
                (res : any) => { resolve(res.data) }, 
                (err : any) => { reject(err) }
            )
        })
    }

    // ########## SALES ##########
    getSales(query? : SalesQuery) : Promise<ApiAsset[]>{
        // https://fn.airwire.io/docs/#/sales/get_wiremarket_v1_sales
        let ENDPOINT = "wiremarket/v1/sales"
        return new Promise((resolve, reject)=>{
            let PARAMS = query ? this.buildParams(query) : ""
            this.http.get<ApiResponse<ApiSale>>(API + ENDPOINT + PARAMS).subscribe(
                async (res) => { 
                    let assets : ApiAsset[] = []
                    res.data.forEach((sale : ApiSale)=>{ 
                        let a = sale.assets[0]
                        a.sale = sale
                        assets.push(a)
                    })
                    resolve(assets)
                }, 
                (err : any) => { reject(err) }
            )
        })
    }
    getRawSales(query? : SalesQuery) : Promise<ApiSale[]>{
        // https://fn.airwire.io/docs/#/sales/get_wiremarket_v1_sales
        let ENDPOINT = "wiremarket/v1/sales"
        return new Promise((resolve, reject)=>{
            let PARAMS = query ? this.buildParams(query) : ""
            this.http.get<ApiResponse<ApiSale>>(API + ENDPOINT + PARAMS).subscribe(
                (res : any) => { resolve(res.data) }, 
                (err : any) => { reject(err) }
            )
        })
    }
    getSaleID(sale_id: number | string): Promise<ApiSale>{
        // https://fn.airwire.io/docs/#/schemas/get_wiremarket_v1_sales
        let ENDPOINT = "wiremarket/v1/sales"
        return new Promise((resolve, reject)=>{
            let PARAMS = "/" + sale_id
            this.http.get<ApiResponse<ApiSale>>(API + ENDPOINT + PARAMS).subscribe(
                (res : any) => { resolve(res.data) }, 
                (err : any) => { reject(err) }
            )
        })
    }
    getSalesID(sale_ids: (string | number | null)[]): Promise<ApiSaleDictionary>{
        // https://fn.airwire.io/docs/#/schemas/get_wiremarket_v1_sales
        let ENDPOINT = "wiremarket/v1/sales"
        return new Promise((resolve, reject)=>{
            let PARAMS = "?ids="
            for (let id of sale_ids) if (id) PARAMS += `${id},`
            this.http.get<ApiResponse<ApiSale>>(API + ENDPOINT + PARAMS.slice(0, -1)).subscribe(
                (res : any) => { 
                    let dict : ApiSaleDictionary = {}
                    res.data.forEach((s : ApiSale)=>{ dict[s.sale_id] = s })
                    resolve(dict) 
                }, 
                (err : any) => { reject(err) }
            )
        })
    }

    // ######### AUCTIONS #########
    getAuctions(query? : AuctionQuery): Promise<ApiAuction[]>{
        // https://fn.airwire.io/docs/#/accounts/get_wiremarket_v1_auctions
        let ENDPOINT = "wiremarket/v1/auctions"
        return new Promise((resolve, reject)=>{
            let PARAMS = query ? this.buildParams(query) : ""
            this.http.get<ApiResponse<ApiAuction>>(API + ENDPOINT + PARAMS).subscribe(
                (res : any) => { resolve(res.data) }, 
                (err : any) => { reject(err) }
            )
        })
    }
    getAuctionID(auction_id : string | number): Promise<ApiAuction>{
        // https://fn.airwire.io/docs/#/accounts/get_wiremarket_v1_auctions__auction_id_
        let ENDPOINT = "wiremarket/v1/auctions/"
        return new Promise((resolve, reject)=>{
            this.http.get<ApiResponse<ApiAuction>>(API + ENDPOINT + auction_id).subscribe(
                (res : any) => { resolve(res.data) }, 
                (err : any) => { reject(err) }
            )
        })
    }
    getAuctionPrice(a : ApiAuction){
        if (a.bids.length){
            let bids = a.bids.sort((a,b) => b.number - a.number)
            return this.apiPrice(bids[0].amount, a.price.token_precision)
        }
        else return this.apiPrice(a.price.amount, a.price.token_precision)
    }

    // ######### HELPERS #########
    getTrxIdFromBlock(block : string) : Promise<string>{
        return new Promise((resolve, reject)=>{
            this.http.get(`https://hyperwire.siliconswamp.info/v1/chain/get_block?block_num_or_id=${block}`).subscribe((res : any)=>{
                if (res.transactions[0]?.trx?.id) resolve(res.transactions[0].trx.id)
                else resolve("")
            }, err => resolve("") )
        })
    }
    buildParams(query : any){
        let res = "?"
        for (let key of Object.keys(query)){
            // console.log(key, query[key] );
            let param = ''
            if (Array.isArray(query[key])){ if (query[key].length) param = query[key].join(",") }
            else if (query[key] !== undefined && query[key] !== '')  param = query[key]
            if (param !== undefined && param !== '') res += (key + "=" + encodeURIComponent(param) + "&")
        }
        return res.slice(0, -1)
    }
    apiPrice(price : string, precision : string | number){
        return +price / (Math.pow(10, +precision))
    }
    numberWithCommas(x: any) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    on(event : string) {
        let sub = new Subject()
        if (this.events[event] && this.events[event].length)
            this.events[event].push(sub)
        
        else this.events[event] = [sub]
        return sub
    }
    emit(event : string, data?: any) {
        if (this.events[event])
            for (let ev of this.events[event])
                ev.next(data);
    }
}

// Query Params
export interface AccountQuery {
    match?: string              // Search for partial account name
    collection_name?: string[]  // Filter by collection name
    schema_name?: string[]      // Filter by schema name
    hide_offers?: boolean       // Hide assets which are used in an offer
    ids?: string[]              // show asset ids matching
    page?: number               // Result Page
    limit?: number              // Results per Page
    order?: 'asc' | 'desc'      // Order direction
}
export interface AssetQuery {
    collection_name?: string[]  // Filter by collection name
    schema_name?: string[]      // Filter by schema name
    owner?: string[]            // Filter by owner
    burned?: boolean            // Filter for burned assets
    match_immutable_name?: string // Search for input in asset name on asset immutable data
    collection_whitelist?: string[] // Show only results from specific collections
    hide_offers?: boolean       // Hide assets which are used in an offer
    ids?: string[]              // show asset ids matching
    lower_bound?: string        // lower bound of primary key (value included)
    upper_bound?: string        // upper bound of primary key (value excluded)
    before?: number             // Only show results before this timestamp in milliseconds (value excluded)
    after?: number              // Only show results after this timestamp in milliseconds (value excluded)
    page?: number               // Result Page
    limit?: number              // Results per Page
    order?: 'asc' | 'desc'      // Order direction
    sort?: 'asset_id' | 'minted' | 'updated' | 'transferred' | 'name'  // Column to sort
}
export interface TransferQuery {
    asset_id?: string[] 
    account?: string[]           
    sender?: string[]           
    recipient?: string[]           
    collection_name?: string[] 
    schema_name?: string[]     
    collection_whitelist?: string[]
    memo?: string  // Search for exact memo
    match_memo?: string  // Search for text in memo
    hide_contracts?: boolean      
    lower_bound?: string
    upper_bound?: string
    before?: number
    after?: number
    page?: number
    limit?: number
    order?: 'asc' | 'desc'  
}
export interface OfferQuery {
    account?: string[]           
    sender?: string[]           
    memo?: string  // Search for exact memo
    match_memo?: string  // Search for text in memo
    recipient?: string[]           
    is_recipient_contract?: boolean
    asset_id?: string[] 
    collection_name?: string[] 
    schema_name?: string[]     
    account_whitelist?: string[]
    collection_whitelist?: string[]
    sender_asset_whitelist?: string[]
    recipient_asset_whitelist?: string[]
    hide_contracts?: boolean      
    hide_empty_offers?: boolean
    ids?: string[] // of offer IDs
    lower_bound?: string
    upper_bound?: string
    before?: number
    after?: number
    page?: number
    limit?: number
    order?: 'asc' | 'desc'  
    sort?: 'created' | 'updated'
    state?: 0 | 1 | 2 | 3 | 4 | 5
    // Offer State 
    //      0: PENDING - Offer created and valid
    //      1: INVALID - Assets are missing because ownership has changed
    //      2: UNKNOWN - Offer is not valid anymore
    //      3: ACCEPTED - Offer was accepted
    //      4: DECLINED - Offer was declined by recipient
    //      5: CANCELLED - Offer was canceled by sender)
}
export interface SalesQuery {
    state?: number              // 0: WAITING - Sale created but offer was not send yet, 1: LISTED - Assets for sale, 2: CANCELED - Sale was canceled, 3: SOLD - Sale was bought4: INVALID - Sale is still listed but offer is currently invalid (can become valid again if the user owns all assets again)
    collection_name?: string[]  // Filter by collection name
    schema_name?: string[]      // Filter by schema name
    owner?: string[]            // Filter by owner
    buyer?: string[]            // Filter by owner      
    seller?: string[]           // Filter by owner
    asset_id?: string           // Asset id in the offer
    burned?: boolean            // Filter for burned assets
    match_immutable_name?: string // Search for input in asset name on asset immutable data
    collection_whitelist?: string[] // Show only results from specific collections
    hide_offers?: boolean       // Hide assets which are used in an offer
    lower_bound?: string        // lower bound of primary key (value included)
    upper_bound?: string        // upper bound of primary key (value excluded)
    before?: number             // Only show results before this timestamp in milliseconds (value excluded)
    after?: number              // Only show results after this timestamp in milliseconds (value excluded)
    page?: number               // Result Page
    limit?: number              // Results per Page
    order?: 'asc' | 'desc'      // Order direction
    sort?: 'created' | 'updated' | 'sale_id' | 'price' | 'name'  // Column to sort
    min_price?: number          // Minimum price requirement
    max_price?: number          // Maximum price limit
    symbol?: string
}
export interface CollectionQuery {
    author?: string[]           // Get collections by author
    match?: string              // Search for input in collection name
    authorized_account? : string // Filter for collections which the provided account can use to create assets
    collection_whitelist?: string[] // Show only results from specific collections
    ids?: string[]              // show asset ids matching
    lower_bound?: string        // lower bound of primary key (value included)
    upper_bound?: string        // upper bound of primary key (value excluded)
    before?: number             // Only show results before this timestamp in milliseconds (value excluded)
    after?: number              // Only show results after this timestamp in milliseconds (value excluded)
    page?: number               // Result Page
    limit?: number              // Results per Page
    order?: 'asc' | 'desc'      // Order direction
    sort?: 'created' | 'collection_name'  // Column to sort
}
export interface SchemaQuery {
    collection_name?: string[] | string  // Get collections by author
    authorized_account?: string // Filter for schemas the provided account can edit
    schema_name?: string        // Get collections by author
    match?: string              // Search for input in collection name
    collection_whitelist?: string[] // Show only results from specific collections
    ids?: string[]              // show asset ids matching
    lower_bound?: string        // lower bound of primary key (value included)
    upper_bound?: string        // upper bound of primary key (value excluded)
    before?: number             // Only show results before this timestamp in milliseconds (value excluded)
    after?: number              // Only show results after this timestamp in milliseconds (value excluded)
    page?: number               // Result Page
    limit?: number              // Results per Page
    order?: 'asc' | 'desc'      // Order direction
    sort?: 'created' | 'schema_name'  // Column to sort
}
export interface AuctionQuery {
    bidder?: string
    participant?: string
    hide_empty_auctions?: boolean
    show_seller_contracts?: boolean
    asset_id?: string
    symbol?: string
    account?: string // Filter accounts that are either seller or buyer 
    buyer?: string[]
    seller?: string[]
    min_price?: number
    max_price?: number
    collection_name?: string[]  // Filter by collection name
    schema_name?: string[]      // Filter by schema name
    burned?: boolean            // Filter for burned assets
    owner?: string[]            // Filter by owner
    match_immutable_name?: string // Search for input in asset name on asset immutable data
    collection_whitelist?: string[] // Show only results from specific collections
    lower_bound?: string        // lower bound of primary key (value included)
    upper_bound?: string        // upper bound of primary key (value excluded)
    before?: number             // Only show results before this timestamp in milliseconds (value excluded)
    after?: number              // Only show results after this timestamp in milliseconds (value excluded)
    page?: number               // Result Page
    limit?: number              // Results per Page
    order?: 'asc' | 'desc'      // Order direction
    sort?: 'created' | 'updated' | 'ending' | 'auction_id' | 'price'  // Column to sort
    state?: 0 | 1 | 2 | 3 | 4 | number[]
    // Auction state
        // 0: WAITING: Auction created but assets were not transferred yet
        // 1: LISTED - Auction pending and open to bids
        // 2: CANCELED - Auction was canceled
        // 3: SOLD - Auction has been sold
        // 4: INVALID - Auction ended but no bid was made)
}

// Data structures
export interface ApiAsset {
    asset_id: string
    auctions: { market_contract: 'wire.market', auction_id: string }[]
    auction?: ApiAuction
    backed_tokens: []
    burned_at_block: null | string
    burned_at_time: null | string
    burned_by_account: null | string
    collection: ApiCollection
    contract: string
    data: AttributeValueMap
    immutable_data: AttributeValueMap
    is_burnable: boolean
    is_transferable: boolean
    minted_at_block: string
    minted_at_time: string
    mutable_data: {}
    name: string
    owner: string
    sales: { market_contract: 'wire.market', sale_id: string }[]
    sale?: ApiSale
    chainSale?: AssetSale
    schema: ApiSchema
    template: null
    template_mint: string
    transferred_at_block: string
    transferred_at_time: string
    updated_at_block: string
    updated_at_time: string
    edition_id? : number
    mint_index? : number
    minter?: string
    thumbnail?: string
}
export interface AssetHistory {
    type: 'log' | 'transfer' | 'sale' | 'offer'
    id: string
    info: string
    txid: string
    date: Date
    block: string
    data?: ApiTransfer | ApiSale | ApiOffer
    creator?: string
}
export interface ApiSale {
    market_contract: "wire.market",
    assets_contract: "wire.nft",
    sale_id: string,
    seller: string,
    buyer: string,
    offer_id: string,
    price:{
       token_contract: string,
       token_symbol: string,
       token_precision: number,
       median: null,
       amount: string
    },
    listing_price: string,
    listing_symbol: "WIRE",
    assets: ApiAsset[],
    maker_marketplace: "airwire",
    taker_marketplace: null,
    collection_name: string,
    collection: ApiCollection,
    is_seller_contract: false,
    updated_at_block: string,
    updated_at_time: string,
    created_at_block: string,
    created_at_time: string,
    state: number
}
export interface ApiSaleDictionary {
    [key: string] : ApiSale
}
export interface ApiCollection {
    allow_notify: boolean
    author: string
    authorized_accounts: string[]
    collection_name: string
    created_at_block: string
    created_at_time: string
    img: null | string
    market_fee: number
    name: string
    notify_accounts: string[]
    imgLoaded? : boolean
    data? : any
}
export interface ApiSchema {
    created_at_block: string
    created_at_time: string
    format: { name: string, type: string }[]
    schema_name: string
}
export interface ApiOwner {
    account: string
    assets: string
}
export interface ApiAccount {
    collections: {
        collection: ApiCollection
        assets: string
    }[]
    templates: {
        collection_name: string
        template_id: null
        assets: string
    }[]
    assets: string
}
export interface ApiLog {
    log_id: string,
    name: string,
    data: any,
    txid: string,
    created_at_block: string,
    created_at_time: string
}
export interface ApiTransfer {
    transfer_id: string
    contract: string
    sender_name: string
    recipient_name: string
    memo: string
    txid: string
    assets: ApiAsset[]
    created_at_block: string
    created_at_time: string
}
export interface ApiOffer {
    contract: string
    offer_id: string
    sender_name: string
    recipient_name: string
    memo: string
    state: number
    txid: string
    sender_assets: ApiAsset[]
    recipient_assets: ApiAsset[]
    is_sender_contract: boolean
    is_recipient_contract: boolean
    updated_at_block: string
    updated_at_time: string
    created_at_block: string
    created_at_time: string
}
export interface ApiAuction {
    market_contract: "wire.market"
    assets_contract: "wire.nft"
    auction_id:	string
    seller:	string
    buyer: string
    price: {
        token_contract:	string
        token_symbol: string
        token_precision: number
        amount:	string
    }
    assets: ApiAsset[]
    bids: ApiAuctionBid[]
    maker_marketplace:	"airwire"
    taker_marketplace: null
    claimed_by_buyer: boolean
    claimed_by_seller: boolean
    collection: ApiCollection
    end_time: string
    is_seller_contract: boolean
    updated_at_block: string
    updated_at_time: string
    created_at_block: string
    created_at_time: string
    state: 0 | 1 | 2 | 3 | 4 | number[]
    // Auction state
        // 0: WAITING: Auction created but assets were not transferred yet
        // 1: LISTED - Auction pending and open to bids
        // 2: CANCELED - Auction was canceled
        // 3: SOLD - Auction has been sold
        // 4: INVALID - Auction ended but no bid was made)
}
export interface ApiAuctionBid {
    number: number
    account: string
    amount: string
    created_at_block: string
    created_at_time: string
    txid: string
}

// Extras
export interface ApiResponse<T> {
    success: boolean
    data: T[]
    query_time: number
}
export interface ApiResponseSingle<T> {
    success: boolean
    data: T
    query_time: number
}
export interface AttributeValueMap {
    [key: string]: any
}

export interface AssetSale {
    forSale: true;
    sale_id: string;
    seller: string;
    asset_id: string;
    offer_id: string;
    listing_price: string;
    settlement_symbol: string;
    collection_fee: number;
    asset_royalties: Royalty[];
}
export interface Royalty {
    fee: string;
    user: string;
}

export type SchemaTypes =   "int8"   | "int16"   | "int32"   | "int64"   | "uint8"   | "uint16"   | "uint32"   | "uint64"   | "fixed8"   | "fixed16"   | "fixed32"   | "fixed64"   | "bool"   | "bytes"   | "string"   | "image"   | "file"   | "ipfs"   | "float"   | "double" | "int8[]" | "int16[]" | "int32[]" | "int64[]" | "uint8[]" | "uint16[]" | "uint32[]" | "uint64[]" | "fixed8[]" | "fixed16[]" | "fixed32[]" | "fixed64[]" | "bool[]" | "bytes[]" | "string[]" | "image[]" | "file[]" | "ipfs[]" | "float[]" | "double[]";

export type SchemaObject = { name: string, type: SchemaTypes , parent?: number };