import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Name, Action, Serializer, PublicKey } from '@greymass/eosio';
import { FormControl, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { WIRE_API } from '../../_constants/constants';

import { SystemService } from '../../_services/system.service';
import { AuthService, User } from '../../_services/auth.service';
import { CryptoService, Key } from '../../_services/crypto.service';
import { ContractService } from '../../_services/contract.service';
import { ConnectService, MetamaskError } from "../../_services/connect.service";

import { LoginComponent } from '../login/login.component';

import { ethers } from "ethers";
import { BigNumber } from "@ethersproject/bignumber";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertController } from 'src/app/_services/alert.service';
import { LoadingController } from 'src/app/_services/load.service';

const eosjsAccountName = require('eosjs-account-name');
const md5 = require('md5');

const usernameRegex = new RegExp("[^a-z1-5]+")

@Component({
    selector: 'app-reset',
    templateUrl: './reset.component.html',
    styleUrls: ['./reset.component.scss'],
})
export class ResetComponent implements OnInit {
    @Input() action?: string

    username?: string
    passwordType: string = 'password'
    passwordIcon: string = 'eye-off-outline'

    metamask_register_form!: FormGroup;
    localKeyFromMetamask: any = null;

    username_form!: FormGroup
    new_username?: string
    username_error?: string
    valid_username?: boolean = undefined
    takenUsernames?: string[] = []

    custom_passphrase? : boolean

    connectedAddress? : string

    constructor(
        private contract : ContractService,
        public connect: ConnectService,
        private alert: AlertController,
        private crypto : CryptoService,
        public system: SystemService,
        private auth: AuthService,
        private load: LoadingController,
        public dialogRef: MatDialogRef<ResetComponent>,
        public dialog: MatDialog,

        // public modal: ModalController,
        // public modal2: ModalController,

        private http: HttpClient) {

    }

    ngOnInit() {
        this.connect.connect()

        this.metamask_register_form = new FormGroup({
            username: new FormControl('', (x: any) => { return this.checkUsername(x) }),
            passphrase: new FormControl('', (x: any) => { return null }),
            custom_passphrase: new FormControl(false),
        });

        this.username_form = new FormGroup({
            username: new FormControl('', (x: any) => { return this.checkUsername(x) })
        });

        this.http.post(WIRE_API + '/getUsernames', {}).subscribe((res: any) => {
            this.takenUsernames = res
        })

        this.connect.on('accountsChanged').subscribe((accounts: any) => {
            this.localKeyFromMetamask = null;
        });

        this.auth.on('address-set').subscribe((res : any)=>{
            this.connectedAddress = res
        })
        this.auth.on('remove-address').subscribe((res : any)=>{
            this.connectedAddress = undefined
        })

        if (this.connect.connected) this.connectedAddress = this.connect.accounts[0]
    }

    // async addWireNetwork() {
    //     if (!(window as any).ethereum) {
    //         // Not Metamask supported browser
    //         const alert = await this.alert.create({
    //             cssClass: 'my-custom-class',
    //             header: 'Open Metamask',
    //             message: `This browser doesn't support Metamask. Click to continue with Metamask browser`,
    //             buttons: [{
    //                 text: 'Continue',
    //                 handler: () => {
    //                     let url = 'https://metamask.app.link/dapp/app.dragonspwan.com'
    //                     window.open(url, '_blank')
    //                 }
    //             }, {
    //                 text: 'Cancel',
    //                 role: 'cancel'
    //             }]
    //         });
    //         await alert.present();
    //     }
    //     else this.connect.addWireNetwork();
    // }

    async connectMetamask() {
        if (!(window as any).ethereum) {
            // Not Metamask supported browser
            const alert = await this.alert.create({
                cssClass: 'my-custom-class',
                header: 'Open Metamask',
                message: `This browser doesn't support Metamask. Click to continue with Metamask browser`,
                buttons: [{
                    text: 'Continue',
                    handler: () => {
                        let url = 'https://metamask.app.link/dapp/app.dragonspwan.com'
                        window.open(url, '_blank')
                    }
                }, {
                    text: 'Cancel',
                    role: 'cancel'
                }]
            });
            await alert.present();
        }
        else this.connect.connect()
    }

    async accountOptions() {
        const alert = await this.alert.create({
            cssClass: 'my-custom-class',
            header: 'MetaMask Options',
            message: `Link a different account via MetaMask, or Disconnect from MetaMask.
                <br><br>Linked Address:<br><span class="courier">${this.connectedAddress}</span>`,
            buttons: [
                {
                    text: 'Link Different Account',
                    handler: () => { this.connect.editAccounts() }
                }, 
                {
                    text: 'Disconnect',
                    handler: () => { this.connect.disconnect() }
                }
            ]
        });
        await alert.present();
    }

    togglePassword() {
        this.passwordType = this.passwordType === 'text' ? 'password' : 'text';
        this.passwordIcon = this.passwordIcon === 'eye-off-outline' ? 'eye-outline' : 'eye-off-outline';
    }

    // USERNAME
    checkUsername(x: FormControl, username?: string) {
        let name = username ? username : x.value
        this.valid_username = false

        if (name) {
            if (usernameRegex.test(name)) this.username_error = "Can only use lowercase letters and numbers 1-5"
            else if (name.length > 12) this.username_error = "Must be less than 12 characters"
            else if (!this.takenUsernames!.includes(name)) this.username_error = "Username does not exists"
            else this.valid_username = true
        }
        else this.username_error = "Username required"

        return this.valid_username ? null : { checkUsername: { valid: false } };
    }

    async generateLocalKey() {
        const loading = await this.load.create({ spinner: 'crescent', message: `Generating Keys...`, cssClass: 'loading-overlay', backdropDismiss: false });
        await loading.present();

        let username = this.metamask_register_form.value.username;
        let passphrase = this.metamask_register_form.value.custom_passphrase && this.metamask_register_form.value.passphrase 
            ? 
            this.metamask_register_form.value.passphrase 
            : 
            this.metamask_register_form.value.username

        console.log('Pass', passphrase);
        

        try {
            let ethereum = (window as any).ethereum;
            let provider = new ethers.providers.Web3Provider(ethereum);
            let signer = provider.getSigner();
            let message = md5(passphrase);
            let signature = await signer.signMessage(message);
    
            this.localKeyFromMetamask = await this.crypto.generateKeySeed(username, signature);
    
            // console.log("signature", signature);
            // console.log("keys", this.localKeyFromMetamask);
            loading.dismiss()
            this.registerMetamask(signature, await signer.getAddress())
        }
        catch (err : any | MetamaskError) {
            loading.dismiss()
            this.system.showToast({ header: "MetaMask Error", message : err.message.replace('MetaMask Message Signature: ', ''), color: 'danger' });
            console.log(err.message ? err.message : err);
        }
        
    }

    async registerMetamask(signature: string, pub_address: string) {
        // const loading = await this.load.create({ spinner: 'crescent', message: `Creating Account...`, cssClass: 'loading-overlay', backdropDismiss: false });
        // await loading.present();

        // //check network
        // // if (!this.connect.isMainNetwork) {
        // //     this.system.showToast({ header: "Invalid network", color: 'danger' });
        // //     return;
        // // }
        // //check username        
        // let username = this.metamask_register_form.value.username.toLowerCase();
        // if (!username) {
        //     this.system.showToast({ header: "Please enter username", color: 'danger' });
        //     return;
        // }
        // //check address
        // let account = this.connectedAddress;
        // if (!account) {
        //     this.system.showToast({ header: "Please link address first", color: 'danger' });
        //     return;
        // }
        // account = account.toLowerCase();
        // let key: Key = this.localKeyFromMetamask;
        // if (!key) {
        //     this.system.showToast({ header: "Please generate local key first", color: 'danger' });
        //     return;
        // }

        // // console.log({ username, account, pkey: key.pub_key });

        // try {
        //     let buff = Serializer.encode({ object: Name.from(username) }); //user    
        //     buff.append(Serializer.encode({ object: account })); //address    
        //     //LOCAL PUBLIC KEY IS SET HERE
        //     buff.append(Serializer.encode({ object: PublicKey.from(key.pub_key) })); //pkey

        //     // let tmp = Serializer.encode({
        //     //     object: [{
        //     //         account: "wire.users",
        //     //         name: "addmetauser",
        //     //         authorization: [{
        //     //             actor: "wire.users",
        //     //             permission: "active"
        //     //         }],
        //     //         data: buff
        //     //     }],
        //     //     type: 'action[]',
        //     //     customTypes: [Action]
        //     // });
        //     // let actions = tmp.array;
            

        //     let ethereum = (window as any).ethereum;
        //     let provider = new ethers.providers.Web3Provider(ethereum);
        //     let signer = provider.getSigner();
        //     const abi = [
        //         "function pushEosTransaction(uint64 rp, bytes actions) returns (boolean)",
        //         "function getRp() view returns (uint64)"
        //     ];
        //     const address = "0x0000000000000000000000000000000000000000";
        //     let contract = new ethers.Contract(address, abi, signer);
        //     let nameValue = eosjsAccountName.nameToUint64("wire.users");
        //     let rp = BigNumber.from(nameValue);


        //     let nonce = await this.auth.getNonce(username);
        //     //alert("Uncomment me!");
        //     let message = {
        //         nonce,
        //         pub_key: key.pub_key,
        //         username,
        //         signature,
        //         address: pub_address
        //     }
        //     let msg = this.crypto.encrypt(message);
        //     // console.log(msg, message);


        //     await this.auth.resetMetamask(username, msg);
        //     // return;

        //     //this metamask call will create an account once confirmed, please use test names only
        //     //error messages from eos network will be handled in catch block
        //     //for example: eth address already in use, username already taken, etc
        //     //accounts are listed in https://local.bloks.io/account/wire.users?loadContract=true&tab=Tables&table=accounts&account=wire.users&scope=wire.users&limit=100&nodeUrl=https%3A%2F%2Fwire.siliconswamp.info&coreSymbol=WIRE&systemDomain=eosio&hyperionUrl=https%3A%2F%2Fhyperwire.siliconswamp.info%2F
        //     //expected errors: username = fabriciowire, mmuser, mmuser2 (already exists)
            
        //     // let res = await contract.pushEosTransaction(rp, actions);

        //     //continue flow
        //     // this.market.fetchSimpleTokens('usd');

        //     let user : User = {
        //         username,
        //         firstName : "",
        //         lastName : "",
        //         email : "",
        //         phone : "",
        //         DOB : undefined,
        //         currency: 'usd',
        //         location: "unknown",
        //         profileImgURL: `https://via.placeholder.com/300?text=${this.metamask_register_form.value.username}`
        //     }
            
        //     loading.dismiss()
        //     this.auth.setKey(key)
        //     this.auth.login(user)
                // this.discord.login(user.username)
        //     this.contract.login(key)
        //     // this.modal.dismiss();
        //     this.dialogRef.close();
        //     this.auth.emit('login', user.username);

        //     setTimeout(() =>{
        //         this.system.showToast({ header: `Greetings, ${user.username}!`, color: 'success', duration: 1500 });
        //     }, 500);
            
        // } catch (err: any) {
        //     loading.dismiss()

        //     if (err.code && err.code == 4001)
        //         this.system.showToast({ header: "MetaMask Error", message : err.message.replace('MetaMask Tx Signature: ', ''), color: 'danger' });
        //     else
        //         this.system.showToast({ header: "MetaMask Error", message: this.getErrorMessageFromRPC(err), color: 'danger' });

        //     console.log(err.message ? err.message : err);
        // }
    }

    getErrorMessageFromRPC(e: any): string {
        let message = "Error occurred in WIRE network.";
        let substring = "[ethjs-query] while formatting outputs from RPC";
        if (e.message && e.message.indexOf(substring) > -1) {
            let parts = e.message.split("'");
            if (parts.length > 0) {
                let error = JSON.parse(parts[1]);
                if (error && error.value && error.value.data) {
                    message = error.value.data;
                }
                if (message && message.length > 1) message = message[0].toUpperCase() + message.slice(1);
            }
        }
        message = message.replace('assertion failure with message:', '')
        return message;
    }

    async openLogin(){
        // this.modal.dismiss()
        this.dialog.open(LoginComponent, {
            panelClass: 'secondary-modal'
        });
        

        // const modal = await this.modal2.create({
        //     component: LoginComponent,
        //     swipeToClose: true,
        //     cssClass: 'welcome-modal',
        // });
        // return await modal.present();  

        this.dialogRef.close();
    }
}



