import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from './_modals/login/login.component';
import { RegisterComponent } from './_modals/register/register.component';
import { AuthService } from './_services/auth.service';
import { ConnectService } from './_services/connect.service';
import { SystemService } from './_services/system.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {
    constructor(
        public system : SystemService,
		private dialog : MatDialog,
		public auth: AuthService,
		public connect: ConnectService
    ) {}

    // Light mode dark mode toggle
	toggleTheme(ev: any) {
        // console.log(ev);
        this.system.theme = this.system.theme == 'light' ? 'dark' : 'light';
    }
	// Gets appropriate ion-icon string based on dark / light mode
	get icon() : string {
		return this.system.theme == 'light' ? 'moon-outline' : 'sunny-outline'
	}

    // Web3 Wallet connect
	connectWallet() {
		const dialogRef = this.dialog.open(RegisterComponent, {
			panelClass: 'secondary-modal'
		})
	}
}
