import { environment } from "src/environments/environment"

export const MANDOX_ACCOUNT = 'mandox';
export const MANDOX_MARKET_FEE = .08;
export const MANDOX_API = 'https://api.mandoxcreate.io'
export const HYPERION : string = "https://hyperwire.siliconswamp.info"
export const CHAIN : string = "https://swamprod.airwire.io"
export const WIRE_API : string = 'https://api.airwire.io'

export const IPFS_GATEWAY : string = "https://ipfs.airwire.io/ipfs/"
export const IPFS_SUFFIX : string = "/image.png"

export const AES_KEY : string = "meta-secret"
export const BLOKS : string = "https://local.bloks.io/account/{{ACCOUNT}}?nodeUrl=https%3A%2F%2Fwire.siliconswamp.info&coreSymbol=WIRE&systemDomain=eosio"
export const BLOKS_TRX : string = "https://local.bloks.io/transaction/{{TRX_ID}}?nodeUrl=https%3A%2F%2Fwire.siliconswamp.info&coreSymbol=WIRE&systemDomain=eosio"

export const DEFAULT_SETTINGS : any = {
    dark: false,
    bio: false,
    pin: false
}

export const CURRENCIES : any = [
    { 
        symbol: "USD",
        name: "US Dollar" 
    },
    { 
        symbol: "EUR",
        name: "Euro" 
    },
    {
        symbol: "GBP",
        name: "British Pound"
    }
]
export const CURRENCY_SYMBOLS : any = {
    usd: "$",
    eur: "€",
    gbp: "£"
}