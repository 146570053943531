import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, AfterViewInit, ViewChild, Input, Output, EventEmitter, OnInit, AfterContentInit, HostBinding, ElementRef, OnChanges, OnDestroy, NgZone, Attribute } from "@angular/core";

@Component({
    selector: 'app-drop-down',
    templateUrl: 'drop-down.html',
    styleUrls: ['drop-down.scss'],
    animations: [
        trigger('grow', [
            state('init', style({ height: '{{min}}px', overflow: 'hidden', opacity: 0 }), { params: { min: 0 } }),
            state('shrink', style({ height: '{{min}}px', overflow: 'hidden'}), { params: { min: 0 } }),
            state('grow', style({ height: '{{targetHeight}}px' }), { params: { targetHeight: 0 } }),

            state('shrink-toggle', style({ height: '{{min}}px'}), { params: { min: 0 } }),
            state('grow-toggle', style({ height: '{{targetHeight}}px'}), { params: { targetHeight: 0 } }),

            transition('init <=> grow', [
                style({ height: '{{min}}px', overflow: 'hidden', opacity: 0 }),
                animate('{{speed}} ease', style({ height: '{{targetHeight}}px', opacity: 1 })),
            ], { params: { targetHeight: 0, speed: '.3s', min: 0 } }),
            transition('init <=> shrink', [
                style({ height: '{{min}}px', overflow: 'hidden', opacity: 0 }),
                animate('{{speed}} ease', style({ height: '{{targetHeight}}px', opacity: 1 })),
            ], { params: { targetHeight: 0, speed: '.3s', min: 0 } }),

            transition('shrink => grow', [
                style({ height: '{{min}}px', overflow: 'hidden' }),
                animate('{{speed}} ease', style({ height: '{{targetHeight}}px' })),
            ], { params: { targetHeight: 0, speed: '.3s', min: 0 } }),
            transition('grow => shrink', [
                style({ height: '{{targetHeight}}px', overflow: 'hidden' }),
                animate('{{speed}} ease', style({ height: '{{min}}px' })),
            ], { params: { targetHeight: 0, speed: '.3s', min: 0 } }),

            transition('shrink-toggle => grow-toggle', [
                style({ height: '{{min}}px' }),
                animate('{{speed}} ease', style({ height: '{{targetHeight}}px' })),
            ], { params: { targetHeight: 0, speed: '.3s', min: 0 } }),
            transition('grow-toggle => shrink-toggle', [
                style({ height: '{{targetHeight}}px' }),
                animate('{{speed}} ease', style({ height: '{{min}}px' })),
            ], { params: { targetHeight: 0, speed: '.3s', min: 0 } }),

            // Leaving animation
            transition(':leave', [
                style({ height: '{{targetHeight}}px', overflow: 'hidden', opacity: 1 }),
                animate('{{speed}} ease', style({ height: '{{min}}px', opacity: 0 })),
            ], { params: { targetHeight: 0, speed: '.3s', min: 0 } })
        ])
    ]
})
export class DropDownComponent implements AfterViewInit, OnDestroy {
    @Input('close') canClose: boolean = true;
    @HostBinding('@grow') grow?: { value: string, params: any }
    @HostBinding('class.button') get buttonClass() {
        return this.hasButton;
    }
    @Input('speed') speed: string | number = '.3s';
    @Input('min-size') minSize: number = 0;
    @ViewChild('overflow') overflow?: ElementRef<HTMLElement>;
    canToggle: boolean;
    private _open: boolean = false;
    @Input('open') set open(val: boolean) {
        // console.log('Is Open', val);
        this._open = val;
        if(this.overflow?.nativeElement.scrollHeight) {
            this.maxHeight = this.overflow?.nativeElement.scrollHeight;
        }
        if (this.canToggle) {
            if (val) {
                this.grow = { value: 'grow-toggle', params: { targetHeight: this.maxHeight, speed: this.speed, min: this.minSize } };
            } else {
                this.grow = { value: 'shrink-toggle', params: { targetHeight: this.maxHeight, speed: this.speed, min: this.minSize } };
            }
        }
    }
    get open() {
        return this._open;
    }

    hasButton = false;

    maxHeight: number = 0;

    constructor(
        private zone: NgZone,
        private element: ElementRef<HTMLElement>, 
        @Attribute('toggle') private toggle: any, 
        @Attribute('no-button') public noButton: any
    ) {
        this.canToggle = this.toggle != undefined;
        this.noButton = noButton != undefined;
        this.hasButton = this.canToggle && !this.noButton;
        
        window.addEventListener('resize', () => {
            if(this.overflow?.nativeElement.scrollHeight) {
                this.maxHeight = this.overflow?.nativeElement.scrollHeight;
                if(this.canToggle) {
                    this.hasButton = !this.noButton && this.maxHeight > this.minSize;
                    if(this._open) {
                        this.grow = { value: 'grow-toggle', params: { targetHeight: this.maxHeight, speed: '0s', min: this.minSize } };
                    }
                } else {
                    this.grow = { value: 'grow', params: { targetHeight: this.maxHeight, min: this.minSize } };
                }
            }
        //     this.maxHeight = this.element.nativeElement.clientHeight;
        //     this.open = this._open;
        })
        // console.log('init');
        
        this.grow = { value: 'init', params: { min: this.minSize } }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            let height = this.maxHeight = this.overflow ? this.overflow.nativeElement.clientHeight : this.element.nativeElement.clientHeight;
        
            this.hasButton = this.hasButton && this.maxHeight > this.minSize;

            if (!this.canToggle) {
                // console.log('grow');
                
                this.grow = { value: 'grow', params: { targetHeight: height, speed: this.speed, min: this.minSize } };
            } else {
                if (this.open) {
                    this.grow = { value: 'grow-toggle', params: { targetHeight: height, speed: '.3s', min: this.minSize } };
                } else {
                    this.grow = { value: 'shrink-toggle', params: { targetHeight: height, speed: '.3s', min: this.minSize > height ? height : this.minSize } };
                }
            }
        }, 10)
    }

    ngOnDestroy(): void {
        // console.log('destroy', this.maxHeight);
        
        // this.grow = { value: 'shrink', params: { targetHeight: this.maxHeight, speed: this.speed } }
    }
}