import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from 'src/app/_modals/login/login.component';
import { AuthService } from 'src/app/_services/auth.service';
import { ConnectService } from 'src/app/_services/connect.service';

import { SystemService } from 'src/app/_services/system.service';


@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	connected = false;
	account?: string;

	// For mobile Nav bar - toggled when hamburger clicked will show mobile menu
	showMobileMenu: boolean = false;
	// For mobile Nav bar - toggled when mangifying glass clicked will show full search bar
	showSearchBar: boolean = false;

	constructor(
		public system : SystemService,
		private dialog : MatDialog,
		public auth: AuthService,
		public connect: ConnectService
	) { }

	ngOnInit() {}

	showSearch() {
		this.showSearchBar = !this.showSearchBar;
	}
	
	// Web3 Wallet connect
	connectWallet() {
		const dialogRef = this.dialog.open(LoginComponent, {
			panelClass: 'secondary-modal'
		})
	}

	// Light mode dark mode toggle
	toggleTheme(ev: any) {
        // console.log(ev);
        this.system.theme = this.system.theme == 'light' ? 'dark' : 'light';
    }
	// Gets appropriate ion-icon string based on dark / light mode
	get icon() : string {
		return this.system.theme == 'light' ? 'moon-outline' : 'sunny-outline'
	}
}