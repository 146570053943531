import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./_pages/home/home.module').then( m => m.HomePageModule)
    },
    // {
    //     path: 'explore',
    //     loadChildren: () => import('./_pages/explore/explore.module').then( m => m.ExplorePageModule)
    // },
    // {
    //     path: 'create',
    //     loadChildren: () => import('./_pages/create/create.module').then( m => m.CreatePageModule),
    //     canActivate: [AuthGuard],
    // },
    // {
    //     path: 'profile',
    //     loadChildren: () => import('./_pages/profile/profile.module').then( m => m.ProfilePageModule),
    //     canActivate: [AuthGuard],
    // },
    // {
    //     path: 'collection',
    //     loadChildren: () => import('./_pages/collection/collection.module').then(m => m.CollectionPageModule)
    // },
    // {
    //     path: 'message',
    //     loadChildren: () => import('./_pages/message/message.module').then(m => m.MessagePageModule),
    //     canActivate: [AuthGuard],
    // },
    {
        path: '',
        redirectTo: '',
        pathMatch: 'full'
    },

    {
        path: '**',
        redirectTo: '',
        pathMatch: 'full'
    },
];

@NgModule({
	imports: [ RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }) ],
	exports: [RouterModule]
})
export class AppRoutingModule { }
