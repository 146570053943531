import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { WireNFTAsset } from "src/app/types/types";
import { ContractService } from "src/app/_services/contract.service";
import { ApiAsset } from "src/app/_services/nft.service";

const PREFIX = 'https://ipfs.fuego.airwire.io/';
const SUFFIX = '/image.png?quality=10';


@Component({
    selector: 'app-nft-card',
    templateUrl: './nft-card.component.html',
    styleUrls: ['./nft-card.component.scss']
})
export class NftCardComponent implements OnInit, AfterViewInit {
    // ! ONCE ALL DUMMY DATA REMOVED, REMOVE WireNFTAsset option and clean up all calls.
    @Input() asset?: WireNFTAsset | ApiAsset; 

    imageLoading: boolean = true;

    // If asset has editions
    maxSupply: number | string = 'Ꝏ';

    get assetImage() {
        if( this.asset?.hasOwnProperty('img')) return (<WireNFTAsset>this.asset).img ? (<WireNFTAsset>this.asset).img : 'src\assets\pfp.png';
        else {
            return PREFIX + (<ApiAsset>this.asset).data.file + SUFFIX; 
        }
    }
    get chainLogo(): string {
        return '../../../assets/wire_token.png';
    }

    get price(): number {
        if(this.asset) {
            if((<WireNFTAsset>this.asset)['price']) {
                 return (<WireNFTAsset>this.asset).price!;
            } else {
                if((<ApiAsset>this.asset)['sale']) { 
                    return +(<ApiAsset>this.asset).sale!.price.amount / 10**8;
                }
            }
        }

        return 0;
    }

    constructor(private contract: ContractService) {
        
    }

    ngOnInit() {
        if(this.asset?.hasOwnProperty('edition_id')) {
            switch((<ApiAsset>this.asset).edition_id) {
                case -1:
                    this.maxSupply = '1';
                    break;
                case 0:
                    break;
                default:
                    this.getEdition()
                    break;
            }
        }
    }

    ngAfterViewInit(): void {
        
    }

    getEdition(){
        setTimeout(() => {
            this.contract.getRows({
                table: 'editions',
                scope: (<ApiAsset>this.asset)!.collection.collection_name,
            }).then((res : any)=>{
                console.log("GetEdition:", res);
                
                for (let entry of res.rows){
                    if (entry.edition_id == (<ApiAsset>this.asset)!.edition_id){
                        this.maxSupply = entry.max_supply.toString();
                    }
                }
            }, (err:any) => {
    
            })
        }, 100)
    }
}